import type { ReactElement } from 'react';
import type { GetStaticProps, GetStaticPropsContext } from 'next';

import * as React from 'react';
import styled from '@emotion/styled';
import { BaseLayout } from '../layouts/BaseLayout';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getNonPageNamespaces } from '../utils/get-non-page-namespaces';
import { Link } from '../components/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const Root = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  backgroundImage: `url(${process.env.PROJECT_ASSET_URL}/13330e74-912d-403d-994d-7dd6049dac43/?fmt=webp)`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

const Content = styled(Container)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

export default function PageNotFound() {
  const { t } = useTranslation(['common', '404']);

  return (
    <>
      <NextSeo
        title={t('common:title')}
        description={t('common:description')}
      />
      <Root>
        <Content maxWidth="md">
          <Typography component="p" variant="h1" align="center">
            {t('404:title', 'Oops, Looks like you\'re lost!')}
          </Typography>
          <Typography component="p" variant="subtitle1" align="center" mb={2}>
            {t('404:description', 'This page was not found.')}
          </Typography>
          <Button
            component={Link}
            href="/"
            variant="contained"
            color="primary"
          >
            {t('404:button', 'Back to homepage')}
          </Button>
        </Content>
      </Root>
    </>
  )
}

// Adding getLayout property. Allowing us to return a React component for the layout.
// This allows us to define the layout on a per-page basis. Since we're returning a function,
// we can have complex nested layouts if desired.
PageNotFound.getLayout = function getLayout(page: ReactElement) {
  const { props } = page;
  const { pageContext } = props;
  return (
    <BaseLayout pageType="404" pageContext={pageContext}>{page}</BaseLayout>
  )
};

export const getStaticProps: GetStaticProps = (async (context: GetStaticPropsContext) => {
  const locale: string = (context.locale || context.defaultLocale) as string;
  const page = {
    title: null,
    body: null,
    locale: context.locale,
  };
  const { title, body } = page;
  const pageContext = {
    locale: page.locale,
    locales: context.locales,
    defaultLocale: context.defaultLocale,
    slugs: [],
    slug: '404',
    breadcrumbs: [],
  };

  return {
    props: {
      title,
      body,
      global: {
        popular: [],
        latest: [],
      },
      pageContext,
      // Get our translations by namespace and locale.
      ...(await serverSideTranslations(locale as string, ['common', '404', ...getNonPageNamespaces(locale)])),
    },
    // Incremental Static Regeneration (ISR).
    // https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration
    revalidate: parseInt(process.env.PROJECT_SSR_REVALIDATE_SECONDS || '') || 60,
  };
});